import { createBrowserHistory } from 'history'
import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { B2bWebinar } from '../breakout/b2b-hall'
import BreakoutPage2 from '../breakout/breakout-page-2'
import landing from '../landing-page/landing'
import LoginPage from '../login/login-page'
import RootComponent from '../login/root-component'

export default class RouterContainer extends Component {
    render() {
        return (
            <div>
                 <Router history={createBrowserHistory}>
                    <Switch>
                        <Route exact path="/" component={landing} />
                        {/* <Route exact path="/main/:uid" component={RootComponent} />
                        <Route exact path="/breakout-page2" component={BreakoutPage2} /> */}
                        {/* <Route exact path="/b2bwebinar" component={B2bWebinar} /> */}
                    </Switch>
                </Router>
            </div>
        )
    }
}
