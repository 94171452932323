import React, { Component } from 'react';
// import { PAGETYPE } from '../../functions/helpers/constants';
import './header.css';

import logoImg from '../images/logo.png';
import angleslogo from '../images/res logo.png';
import whiteLogo from '../images/whitemetalogo.png'
import whiteBLogo from '../images/BOEHRINGERLOGO.png'

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerType: this.props.headerType
        }
        // this.logoBtn = this.logoBtn.bind(this);
    }

    componentDidMount() {
console.warn('usr role',this.state.headerType )
    }

    logoBtn = () => {
        // window.open('https://angels-events.web.app/', '_blank')
        window.location.href = '/';
    }

    // goBackToLobby=()=>{
    //     window.location.href='/lobby-page'
    // }


    render() {
        return (
            <div>
                {this.state.headerType === 'user' &&
                    <div>
                        {/* angles logo */}
                        <div className="logopositionleft">
                            <div className="flexlogo">
                                <img src={angleslogo} className="widthangleslogo" alt=""  />
                            </div>
                        </div>
                        {/* boehringer logo */}
                        <div className="logopositionright">
                            <div className="flexlogo">
                                <img src={logoImg} className="widthboihringerlogo" alt=""  />
                            </div>
                        </div>
                    </div>
                }
                {this.state.headerType === 'speaker'  &&

                    <div>
                        <div className="logopositionleft">
                            <div className="flexlogo">
                                <img src={whiteLogo} className="widthangleslogo" alt=""  />
                            </div>
                        </div>
                        <div className="logopositionright">
                            <div className="flexlogo">
                                <img src={whiteBLogo} className="widthboihringerlogo" alt=""  />
                            </div>
                        </div>
                    </div>
                } 
                {this.state.headerType === 'moderator' && 
               <div>
               <div className="logopositionleft">
                   <div className="flexlogo">
                       <img src={whiteLogo} className="widthangleslogo" alt=""  />
                   </div>
               </div>
               <div className="logopositionright">
                   <div className="flexlogo">
                       <img src={whiteBLogo} className="widthboihringerlogo" alt=""  />
                   </div>
               </div>
           </div> }

            </div>
        )
    }
}
