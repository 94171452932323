import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer-page.css';
import lobbyImg from '../images/metla-ild-lobby-icon.png';
import feedbackImg from '../images/metla-ild-feedback-icon.png';
import webinarImg from '../images/WEBINAR.png';
import ArchiveImg from '../images/archive-icon.png';
import displaygallaryImg from '../images/DISPLAY.png';
import entertainmentImg from '../images/ENTERTAINMENT.png';
import agendaImg from '../images/metla-ild-agenda-icon.png';
import logout from '../images/metla-ild-logout-icon.png';
import infoimg from '../images/INFO.png';
import breakoutimg from '../images/breakout-icon.png';
import nurseAgendaImg from '../images/agenda-chart-2.jpg'
import neurologistsAgendaImg from '../images/Agenda-2.jpg'
import tutorialimg1 from '../images/desktopangels1.jpg';
import tutorialimg2 from '../images/mobangels2.jpg';
import menuImg from '../images/menu.png';
import closeImg from '../images/close.png';
import loginFirebaseApp from '../functions/features/login-firebase-app';
import authFirebaseApp from '../functions/features/auth-firebase-app';
import dbFirebaseApp from '../functions/features/db-firebase-app';

const expoName = 'meta-ild-2021';

export default class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isBtnLive: false,
        }
    }

    componentDidMount() {
        this.getUserDetails();
    }

    isMobileDevice = () => {
        if (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        } else {
            return false;
        }
    }

    goToLobby = () => {
        let str = window.location.href;
        // console.warn('string is ::', str.substring(str.lastIndexOf('/')+1))
        if (str.substring(str.lastIndexOf('/') + 1) !== 'entrance') {
            window.location.href = '/entrance'
        }
    }

    goToWebinar = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'webinar-hall') {
            window.location.href = '/webinar-hall'
        }
    }

    goToBreakout = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'breakout-page') {
            window.location.href = '/breakout-page'
        }
    }

    goToArchiveGallary = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'archive-gallary') {
            window.location.href = '/archive-gallary'
        }
    }

    goToDisplayGallary = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'display-gallary') {
            window.location.href = '/display-gallary'
        }
    }

    goToBreakTimeZone = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'entertainment-zone') {
            window.location.href = '/entertainment-zone'
        }
    }

    goToAgenda = () => {
        document.getElementById('location1').style.display = 'block'
    }

    goToInfo = () => {
        document.getElementById('info1').style.display = 'block'
    }

    goToFeedback = () => {
        window.open('https://boehringeringelheim.eu.qualtrics.com/jfe/form/SV_6EagGCEAT29mqpf?&event_ID=EM-0000060365&event_name=META_ILD_Virtual_Radiology_Masterclass_2021&country_code=&event_date=Jun18,2021', '_blank')
    }

    userLogout = () => {
        authFirebaseApp.auth().signOut().then(res => {
            console.log('log out')
            localStorage.clear()
            window.location.href = "/"
        })
        // this.props.goToPage(PAGETYPE.ENTERTAINMENT);
    }
    june18th = () => {
        document.getElementById('session1').style.display = 'block'
        document.getElementById('agendaNurseBtn').classList.add('active');;
        document.getElementById('agendaNeurologistBtn').classList.remove('active');;
        document.getElementById('session2').style.display = 'none'
    }

    june25th = () => {
        document.getElementById('session2').style.display = 'block'
        document.getElementById('agendaNeurologistBtn').classList.add('active');
        document.getElementById('agendaNurseBtn').classList.remove('active');
        document.getElementById('session1').style.display = 'none'
    }

    closePopup = () => {
        document.getElementById('location1').style.display = 'none'
    }

    infoButtonclose = () => {
        document.getElementById('info1').style.display = 'none'
    }
    openbottommenu = () => {
        document.getElementById("bottommenu").classList.remove("bottomaniamtion");
        document.getElementById("Mobmenu").style.display = "none";
        document.getElementById("Mobmenuclose").style.display = "flex";
        if ((document.getElementById("myScrollingChat"))) {
            document.getElementById("myScrollingChat").classList.remove("ishiddenchat");
        }
        if ((document.getElementById("Chat"))) {
            document.getElementById("Chat").classList.add("chaticonposition2");
        }

    }
    closebottommenu = () => {
        document.getElementById("bottommenu").classList.add("bottomaniamtion");
        document.getElementById("Mobmenu").style.display = "flex";
        document.getElementById("Mobmenuclose").style.display = "none";
        if ((document.getElementById("Chat"))) {
            document.getElementById("Chat").classList.remove("chaticonposition2");
        }
    }

    getUserDetails = () => {
        let self = this;
        const db = dbFirebaseApp.firestore();
        const userCollection = db.collection("MedexLive");
        userCollection
            .where("expo", "==", expoName)
            .onSnapshot(function (querySnapshot) {
                querySnapshot.docChanges().forEach(function (change) {
                    if (change.type === "added" && change.type !== "modified") {
                        // console.log("New city: ", change.doc.data());
                        self.setState({ isBtnLive: change.doc.data().isLive });
                    }
                    if (change.type === "modified") {
                        // console.log("Modified city: ", change.doc.data());
                        self.setState({ isBtnLive: change.doc.data().isLive });
                    }
                });
            });
    }

    render() {
        return (
            <div>
                {this.state.isBtnLive &&
                    localStorage.getItem("Location") !== "webinar-hall" && (
                        <button
                            id="quickAccessBtn"
                            onClick={this.goToWebinar}
                            className="btn-hover-shine btn-glow btn-hover-shinebottom"
                        >
                            Quick Access to Live Session
                        </button>
                    )}

                {/* AGENDA POPUP */}
                <div className="modal" id="location1" style={{ display: 'none' }} >
                    <div className="modal-dialog modal-dialog-centered modal-lg" >
                        <div className="modal-content" style={{ overflow: "auto", height: "500px" }}>
                            <div className="modal-header">
                                <h5 className="modal-title green-text" id="staticBackdropLabel">Agenda</h5>

                                <button type="button" className="close black-color" data-dismiss="modal" onClick={this.closePopup}>&times;</button>
                            </div>
                            <div className="agendaButton">
                                <button className="btnBlueGreen active" id='agendaNurseBtn' onClick={this.june18th}>18<sup>th</sup>June 2021</button>
                                <button className="btnBlueGreen margin" id='agendaNeurologistBtn' onClick={this.june25th}>25<sup>th</sup>June 2021</button>
                            </div>



                            <div className="col-sm align-self-center py-4 py-sm-0 px-5 px-sm-4 px-xl-4 scrollbar"
                                id="session1" style={{ overflow: "auto" }}>
                                <table className="table table-responsive-sm">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">No.</th>
                                            <th scope="col">Time</th>
                                            <th scope="col">Subject</th>
                                            {/* <th scope="col">Speaker</th>  */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>20:00 –20:15 </td>
                                            <td>Welcome &amp; Meeting Objectives</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>20:15 –20:45 </td>
                                            <td>Clinical approach to diffuse parenchymal lung diseases </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>20:45 –21:45 </td>
                                            <td>CT diagnosis of diffuse parenchymal lung diseases (Part 1)</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>21:45 –22:00 </td>
                                            <td>Q&amp;A</td>
                                        </tr>

                                        <tr>
                                            <td>5</td>
                                            <td>22:00 –22:05 </td>
                                            <td>Closing remarks</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-sm align-self-center py-4 py-sm-0 px-5 px-sm-4 px-xl-4 scrollbar"
                                id="session2" style={{ overflow: "auto", display: "none" }}>
                                <table className="table table-responsive-sm">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">No.</th>
                                            <th scope="col">Time</th>
                                            <th scope="col">Subject</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>20:00 –20:10 </td>
                                            <td>Welcome &amp; Meeting Objectives</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>20:10 –20:30 </td>
                                            <td>Recap from Session 1 </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>20:30 –21:15 </td>
                                            <td>CT diagnosis of diffuse parenchymal lung diseases (Part 2)</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>21:15 –21:45 </td>
                                            <td>DD of fibrosing lung diseases (incl. Nodular pattern & increased densities)</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>21:45 –22:00</td>
                                            <td>Q&amp;A</td>
                                        </tr>

                                        <tr>
                                            <td>6</td>
                                            <td>22:00 –22:05 </td>
                                            <td>Closing remarks</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* AGENDA POPUP */}



                {/* INFO POPUP */}
                <div className="modal" id="info1" >
                    <div className="modal-dialog modal-dialog-centered" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title green-text" id="staticBackdropLabel">Info</h5>

                                <button type="button" className="close black-color" data-dismiss="modal" onClick={() => this.infoButtonclose()}>&times;</button>
                            </div>
                            <div className="modal-body">

                                {
                                    (this.isMobileDevice()) ?
                                        <img src={tutorialimg2} style={{ width: '100%' }} />
                                        :
                                        <img src={tutorialimg1} style={{ width: '100%' }} />
                                }


                            </div>
                        </div>
                    </div>
                </div>
                {/* INFO POPUP */}

                {/* Mobile Menu Buttons */}
                <div className="mobmenu" id="Mobmenu">
                    <img src={menuImg} className="widthmenuicon" alt="" onClick={this.openbottommenu} />
                </div>
                <div className="mobmenuclose" id="Mobmenuclose">
                    <img src={closeImg} className="widthmenuicon" alt="" onClick={this.closebottommenu} />
                </div>
                {/* Mobile Menu Buttons */}

                <div id="bottommenu" className="bottomaniamtion">
                    <ul className="list-unstyled  flexicon scrolltypewhite">
                        {/* <li className="flexcolumn" onClick={this.goToLobby}>
                            <img src={lobbyImg} className="widthicon" alt="Webinar" />
                            <span className="cenetrtext bottomtext">Lobby</span>
                        </li>
                        <li className="flexcolumn" onClick={this.goToWebinar}>
                            <img src={webinarImg} className="widthicon" alt="Webinar" />
                            <span className="cenetrtext bottomtext">Webinar Hall</span>
                        </li> */}
                        {/* <li className="flexcolumn" onClick={this.goToBreakout} >
                            <img src={breakoutimg} className="widthicon" alt="Breakout" />
                            <span className="cenetrtext bottomtext">breakout Room</span>

                        </li> */}
                        {/* <li className="flexcolumn" onClick={this.goToBreakout2} >
                            <img src={breakoutimg} className="widthicon" alt="Breakout" />
                            <span className="cenetrtext bottomtext">breakout Room 2</span>

                        </li> */}
                        {/* <li className="flexcolumn" onClick={() => this.chatRoomButton(PAGETYPE.CHATROOM)}>
                                <img src={chatroomImg} className="widthicon" alt="Chat" />
                                <span className="cenetrtext bottomtext">Chat Room</span>
                            </li> */}
                        {/* <li id="expohall" className="flexcolumn" onClick={this.goToArchiveGallary}>
                            <img src={ArchiveImg} className="widthicon" alt="Gallary" />
                            <span className="cenetrtext bottomtext">Archive Gallery</span>
                        </li> */}
                        {/* <li className="flexcolumn" onClick={this.goToDisplayGallary}>
                            <img src={displaygallaryImg} className="widthicon" alt="Gallary" />
                            <span className="cenetrtext bottomtext">Display Gallery</span>
                        </li> */}
                        {/* <li className="flexcolumn" onClick={this.goToBreakTimeZone}>
                            <img src={entertainmentImg} className="widthicon" data-toggle="modal" alt="Entertainment"
                                data-target="#filterpopup" />
                            <span className="cenetrtext bottomtext">Entertainment Zone</span>

                        </li> */}
                        {/* <li className="flexcolumn" onClick={this.goToAgenda} >
                            <img src={agendaImg} className="widthicon" data-target="#location1" data-toggle="modal" alt="Location" />
                            <span className="cenetrtext bottomtext">Agenda</span>

                        </li> */}
                        {/* <li className="flexcolumn" onClick={this.goToInfo}>
                            <img src={infoimg} className="widthicon" data-target="#info1" data-toggle="modal" alt="Location" />
                            <span className="cenetrtext bottomtext">Info</span>

                        </li> */}

                        {/* <li className="flexcolumn" onClick={this.goToFeedback} >
                            <img src={feedbackImg} className="widthicon" alt="Feedback" />
                            <span className="cenetrtext bottomtext">Feedback</span>

                        </li> */}

                        <li className="flexcolumn"  >
                            <img src={logout} className="widthicon" alt="Log out" onClick={this.userLogout} />
                            <span className="cenetrtext bottomtext">Log Out</span>

                        </li>

                        {/* <li className="flexcolumn" onClick={() => this.locationButton()}>
                                <img src={locationImg} className="widthicon" data-target="#location1" data-toggle="modal" alt="Location" />
                                <span className="cenetrtext bottomtext">Location</span>

                            </li> */}
                    </ul>
                </div>
            </div>
        )
    }
}
