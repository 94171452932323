import React, { Component } from 'react';
import loginFirebaseApp from '../functions/features/login-firebase-app';
// import { PAGETYPE } from '../../functions/helpers/constants';
import 'bootstrap/dist/css/bootstrap.min.css';
import angleslogo from '../images/whitelogo.png';
import chromeimg from '../images/aaa.png';

// import '../../../css/animate.css';
// import '../../../css/bootstrap.min.css';
// import '../../../css/devices.min.css';
// import '../../../css/font-awesome.min.css';
// import '../../../css/idangerous.swiper.css';
import './login-page.css';
// import { commonFunction } from '../../functions/helpers/analytics-function';
import authFirebaseApp from '../functions/features/auth-firebase-app';

const expoName = 'meta-ild-2021';

export default class LoginPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loginEmail: '',
            loginPassword: '',
        }
        this.loginButton = this.loginButton.bind(this);
        this.authCheck = this.authCheck.bind(this);
        this.getUserDetails = this.getUserDetails.bind(this);
        this.handleEmailKeyPress = this.handleEmailKeyPress.bind(this);
        this.handlePasswordKeyPress = this.handlePasswordKeyPress.bind(this);
        this.handleLoginEmailChange = this.handleLoginEmailChange.bind(this);
        this.handleLoginPasswordChange = this.handleLoginPasswordChange.bind(this);
        // this.initializeWow = this.initializeWow.bind(this);
    }

    // initializeWow() {
    //     var wow = new WOW({
    //         boxClass: 'wow', // animated element css class (default is wow)
    //         animateClass: 'animated', // animation css class (default is animated)
    //         offset: 100, // distance to the element when triggering the animation (default is 0)
    //         mobile: true, // trigger animations on mobile devices (default is true)
    //         live: true, // act on asynchronously loaded content (default is true)
    //         callback: function (box) {
    //             // the callback is fired every time an animation is started
    //             // the argument that is passed in is the DOM node being animated
    //         }
    //     });

    //     wow.init();
    // }

    // componentDidMount() {
    //     authFirebaseApp.auth().onAuthStateChanged((user) => {
    //         if (user) {
    //             console.warn('user is ',user)
    //             if(localStorage.getItem('userRole') === 'speaker'){
    //                 window.location.href = '/speaker-webinar'
    //             }
    //             else if(localStorage.getItem('userRole') === 'moderator'){
    //                 window.location.href = '/moderator-webinar'
    //             }else if(localStorage.getItem('userRole')==='user'){
    //                 window.location.href = "/entrance"
    //             }
    //             else{
    //                 window.location.href = '/entrance'
    //             }
    //           // User is signed in, see docs for a list of available properties
    //           // https://firebase.google.com/docs/reference/js/firebase.User
    //           var uid = user.uid;
    //           // ...
    //         } 
    //         else {
    //             console.warn('user not found')
    //           // User is signed out
    //           // ...
    //         }
    //       });
    // }

    loginButton(e) {
        e.preventDefault();
        this.authCheck();
    }

    handleEmailKeyPress(e) {
        e = e || window.event;
        if (e.keyCode === 13) {
            document.getElementById('login-password-txt').focus();
        }
    }

    handlePasswordKeyPress(e) {
        e = e || window.event;
        if (e.keyCode === 13) {
            document.getElementById('login-btn').click();
        }
    }

    handleLoginEmailChange(e) {
        e.preventDefault();
        this.setState({ loginEmail: e.target.value })
    }

    handleLoginPasswordChange(e) {
        e.preventDefault();
        this.setState({ loginPassword: e.target.value })
    }

    authCheck() {
        let loginEmailId = this.state.loginEmail.toLowerCase();
        loginEmailId = loginEmailId.replace(/ /g, "");
        loginEmailId = loginEmailId.toLowerCase();
        // let loginData = new Promise((resolve, reject) => {
        //     authFirebaseApp.auth().signInWithEmailAndPassword(loginEmailId, loginPassword)
        //         .then(function (user) {
        //             resolve(user);
        //         }).catch(function (error) {
        //             // Handle Errors here.

        //             reject(error)
        //             // ...
        //         });

        // });
        // loginData.then(result => {
        //     console.warn('login Successful')
        // checkForExistingUserforLogin(loginEmailId)
        this.getUserDetails(loginEmailId);

        // }).catch(error => {
        //     console.error(error)
        //     var errorMessage = error.message;
        //     alert(errorMessage)
        // })
    }

    getUserDetails(emailId) {
        const loginPassword = this.state.loginPassword;
        const db = loginFirebaseApp.firestore();
        document.getElementById('login-name').style.display = 'none';
        document.getElementById('loader-login').style.display = 'block';
        const userCollection = db.collection('Users');
        let ExistingUserQuery = new Promise((resolve, reject) => {
            userCollection.where('expo', '==', expoName).where('email', '==', emailId)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        // console.log(doc.id, " => ", doc.data());
                        let myData = doc.data();
                        myData.id = doc.id;
                        resolve(myData);
                    });
                    resolve('Not Found');
                })
                .catch(function (error) {
                    reject(error);
                });
        });
        ExistingUserQuery.then(result => {
            if (result === 'Not Found') {
                alert('User details not found');
                document.getElementById('login-btn').removeAttribute('disabled');

                document.getElementById('login-name').style.display = 'block';
                document.getElementById('loader-login').style.display = 'none';
            } else {
                // commonFunction(PAGETYPE.LOBBY);
                if (loginPassword === result.secretKey) {

                    localStorage.setItem('name', result.name);
                    localStorage.setItem('userId', result.id);
                    localStorage.setItem('country', result.countryName);
                    localStorage.setItem('uid', result.uid);
                    localStorage.setItem('email', result.email);
                    localStorage.setItem('mobile', result.mobileNumber);
                    localStorage.setItem('designation', result.designation);
                    localStorage.setItem('hospital', result.hospital);
                    localStorage.setItem('roomName', 'webinar');
                    if (result.userRole) {
                        localStorage.setItem('userRole', result.userRole);
                    }
                    else {
                        localStorage.setItem('userRole', 'user');
                    }
                    if (result) {
                        console.warn('user is ', result)
                        if (localStorage.getItem('userRole') === 'speaker') {
                            window.location.href = '/b2bwebinar'
                        }
                        else if (localStorage.getItem('userRole') === 'moderator') {
                            window.location.href = '/moderator-webinar'
                        } else if (localStorage.getItem('userRole') === 'user') {
                            window.location.href = "/b2bwebinar"
                        }
                        else {
                            window.location.href = '/b2bwebinar'
                        }
                        // User is signed in, see docs for a list of available properties
                        // https://firebase.google.com/docs/reference/js/firebase.User
                        // ...
                    }
                    else {
                        console.warn('user not found')
                        // User is signed out
                        // ...
                    }
                } else {

                    document.getElementById('login-name').style.display = 'block';
                    document.getElementById('loader-login').style.display = 'none';
                    alert('Your entered password does not match')
                }
                // this.props.goToPage(PAGETYPE.SELECTION);

                // if (emailId === "mohit@shilpmis.com") {
                //     localStorage.setItem('userRole', 'speaker');
                //     this.props.goToPage(PAGETYPE.SPEAKERWEBINAR);
                // } else if (emailId === "akash@shilpmis.com") {
                //     localStorage.setItem('userRole', 'moderator');
                //     this.props.goToPage(PAGETYPE.MODERATORWEBINAR);
                // } else {
                //     localStorage.setItem('userRole', 'user');
                //     this.props.goToPage(PAGETYPE.SELECTION);
                // }
            }
        }).catch(error => {
            console.error('Already existing user check error', error);
        });
    }

    render() {
        return (
            <div>
                <div className="logoposition">
                    <img src={angleslogo} alt="" className="widthlogometaild" />
                </div>
                <div id="content-wrapper">
                    <div className="blocks-container">
                        <div className="block type-1 scroll-to-block" data-id="register">
                            <div className="clip">
                                <div className="bg">
                                </div>
                                <div className="bg-span"></div>
                            </div>
                            <div className="container type-1-text wow flipInX " data-wow-delay="0.3s">
                                <div className="row">
                                    <div className="col-md-12 col-lg-8 col-12  type-1-center" style={{ textAlign: "left" }}>
                                        <div className="big white table margintopsection">
                                            <h1 className="h1 newfont marginbottomtext">META ILD<br />On
                                            &nbsp;18<sup style={{ textTransform: "lowercase" }}>th</sup> &amp; 25<sup style={{ textTransform: "lowercase" }}>th</sup> June 2021.<br /></h1>
                                            {/* 12<sup style={{ textTransform: "lowercase" }}>th</sup> December</h1> */}

                                            <p className="marginbottomtext paddingbottomzero newfont" style={{ fontSize: '22px' }}>Register now to get access to META ILD Virtual Event, with a lineup of informative sessions and an Expert Speaker!</p>
                                            <p class="marginbottomtext paddingbottomzero margintoplogin responsiveposition"><b>Important Note: </b>
                    In order to have the best interactive experience, please make sure to login from your laptop or desktop using Chrome or Edge.</p>
                                            <img src={chromeimg} alt="" className="chromewidth responsiveposition" />
                                            {/* <h1 className="h1 marginbottomtext">Speaker and Moderator Login</h1>

                                            <h1 className="h1 marginbottomtext">Initial Stroke Treatment Virtual Conference!</h1>
                                            <p className="h4 marginbottomtext paddingbottomzero">ANGELS META ACADEMY 2020.<br />Date -
                                    3<sup style={{ textTransform: "lowercase" }}>rd</sup> &amp;
                                    25<sup style={{ textTransform: "lowercase" }}>th</sup> November</p>
                                            <p className="marginbottomtext paddingbottomzero">Register now to get access to a
                                            one-of-its-kind Virtual
                                            Event, with a lineup of
                                    informative sessions and Expert Speakers!</p> */}
                                        </div>


                                    </div>

                                    <div id="registration-form" style={{ display: 'block' }}
                                        className="col-md-8 col-lg-4 col-12 type-1-center">
                                        <div className="flex--form">

                                            {/* <form onSubmit={(e) => this.loginButton(e)}> */}
                                            <input className="required inputclass" id="login-email-txt" style={{ width: "100%" }} value={this.state.loginEmail}
                                                onChange={this.handleLoginEmailChange} onKeyUp={this.handleEmailKeyPress} type="email" placeholder="Your email *" name="email" />
                                            <input className="required inputclass" id="login-password-txt" autoComplete='false' value={this.state.loginPassword}
                                                style={{ width: "100%" }} onKeyUp={this.handlePasswordKeyPress} onChange={this.handleLoginPasswordChange} type="password" placeholder="Password *"
                                                name="password" />

                                            <div className="submit-wraper" style={{ marginTop: "10px" }}>
                                                <button className="button btn-primary-line-dark" style={{ marginBottom: "20px", border: 0, outline: 0 }}
                                                    id="login-btn" onClick={(event) => this.loginButton(event)}>
                                                    <div id="login-name" style={{ display: 'block' }}>Login</div>
                                                    <div className="loaderBtn" id="loader-login" style={{ display: 'none' }}></div>
                                                </button>
                                            </div>
                                            {/* </form> */}
                                        </div>
                                    </div>
                                </div>

                                <p class="marginbottomtext paddingbottomzero margintoplogin responsivepositioninmob"><b>Important Note: </b>
                    In order to have the best interactive experience, please make sure to login from your laptop or desktop using Chrome or Edge.</p>
                                <img src={chromeimg} alt="" className="chromewidth responsivepositioninmob" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
