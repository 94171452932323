import React, { Component } from 'react'
import $ from 'jquery';
import "./chatpanel.css";
import SendImg from "../images/send.png";
import chatImg from "../images/chat.png";
import { generateNewUserId, loadAttendeeMessages, loadMessages, loadModeratorMessages, loadOnlyApprovedMessages, saveMessageToDatabaseforModerator } from '../functions/features/chat/chat-functions';
import CustomNotification from '../helpers/notification/notification';

export default class ChatPanel extends Component {

    constructor(props) {

        super(props);
        this.state = {
            roomName: "",
            shouldShowNotification: false,
        }
    }
    componentWillReceiveProps() {
        this.setState({
            roomName: this.props.roomName
        })
    }
    componentDidMount() {
        this.setState({
            roomName: this.props.roomName
        })
        loadOnlyApprovedMessages(this.props.roomName);
        $(function () {
            var arrow = $(".chat-head img");

            arrow.on("click", function () {
                var src = arrow.attr("src");

                $(".chat-body").slideToggle("fast");
                if (
                    src ===
                    "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
                ) {
                    arrow.attr(
                        "src",
                        "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png"
                    );
                } else {
                    arrow.attr(
                        "src",
                        "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
                    );
                }
            });
        });
    }

    sendMessage = (e) => {
        e.preventDefault();
        console.log(this.props.roomName)
        let msg = document.getElementById("message-txt").value;
        console.log('typed msg', msg)
        if (msg !== "") {
            // console.warn('Sending', msg);
            saveMessageToDatabaseforModerator(msg, this.props.roomName);
            this.setState({ shouldShowNotification: true });
            setTimeout(() => {
                this.setState({ shouldShowNotification: false });
            }, 6000);
        } else {
            console.warn("Button should be disabled now");
        }
    }

    openchat = () => {
        document.getElementById("webinarchatbox").classList.toggle("bottomtotopchat");
        document.getElementById("message-form").classList.toggle("bottomtotopchat");
        document.getElementById("myScrollingChat").classList.toggle("ishiddenchat");
        document.getElementById("bottommenu").classList.add("bottomaniamtion");
        document.getElementById("Mobmenu").style.display = "flex";
        document.getElementById("Mobmenuclose").style.display = "none";
        if ((document.getElementById("Chat"))) {

            document.getElementById("Chat").classList.remove("chaticonposition2");
        }
    };

    render() {
        const { roomName } = this.props;

        return (
            <>
                {this.state.shouldShowNotification && <CustomNotification msg="Message sent successfully. please wait for approval" />}
                <>
                    <div>
                        <div className="mobdisplay chaticonposition" id="Chat">
                            <img src={chatImg} onClick={this.openchat} alt="" className="widthchaticon"></img>
                        </div> 
                        <div className="bottomchatbox" id="webinarchatbox" style={{ position: this.props.position ? this.props.position : '', bottom: this.props.bottom ? this.props.bottom : '', right: this.props.right ? this.props.right : '' }}>
                            <div className="chat-head comdisplay">
                                <h2>Q &amp; A Panel</h2>
                                <img
                                    src="https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png"
                                    title="Expand Arrow"
                                    width="16"
                                    onClick={this.Togglechatbox}
                                    alt="Expand/Collapse"
                                />
                            </div>
                            <div className="chat-body scrolltype" id="myScrollingChat" style={{ display: 'none' }}>
                                <div className="flexform  " id="messages"></div>
                                <form
                                    id="message-form"
                                    onSubmit={this.sendMessage}
                                    className="chat-textnormal"

                                >
                                    <input
                                        type="text"
                                        className="message-editbox"
                                        id="message-txt"
                                        placeholder="Enter Message..."
                                        autoComplete="off"
                                    />
                                    <button type="submit" className="sendarrow">
                                        <img className="send-arrow-img" src={SendImg} alt="Send" />
                                    </button>
                                </form>
                            </div>
                        </div>

                    </div>
                </>
            </>
        )
    }
}
