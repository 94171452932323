import zIndex from "@material-ui/core/styles/zIndex";
import React, { Component } from "react";
import { StartBreakout2Meeting } from "../functions/helpers/breakout-zoom-2";
// import "./b2b-hall.css"

const BreakoutSky = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/spice-board/launchbg.png'
const enterImg = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/private/image2d/spice-board/sb-general/spice-splash-bg1.jpg'

let isVideoMuted = true;

export class B2bWebinar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isVideoEnd : false,
    };
  }

    componentDidMount(){
        // document.getElementById("zmmtg-root").style.backgroundColor = "none"
        StartBreakout2Meeting();
        setTimeout(() => {
          this.setState({
            isVideoEnd : false
          })
        }, 5000);
    }

    toggleCameraMute = () => {
      if (document.querySelector('.send-video-container__btn')) {
          document.querySelector('.send-video-container__btn').click();
      }
      isVideoMuted = !isVideoMuted;
      // document.getElementById('videoBtn').setAttribute('src', isVideoMuted ? '#cameraMuteImg' : '#cameraUnmuteImg');
  }
  render() {
    return(

    <div>
      {this.state.isVideoEnd && 

      <div>
        <img style={{zIndex:"5"}} id="enterImg" className="b2bWebinarHall" src={enterImg} alt="background" />
      </div>
      }
{!this.state.isVideoEnd &&
  <div>

    <img id="b2bWebinar" className="b2bWebinarHall" src={BreakoutSky} alt="background" />
  <button className="cameraBtn" onClick={this.toggleCameraMute}>
    Camera On
  </button>
  </div>
}
    </div>
    )
  }
}

export default B2bWebinar;
