import {
  ZoomMtg
} from '@zoomus/websdk';

// const Meeting_Num = '4798371544';
// const Meeting_Pass = '918760';
// const Name = 'User';
// const API_KEY = 'AWnB258lSHm0UMoCi2_fpw';
// const API_SECRET = 'El01qHKONAK67pUgLwEZJBoqFaIZaIB14LIc';
const Meeting_Num = '99674722540';
const Meeting_Pass = '800746';
let Name = 'Ashish';
const API_KEY = 'ACz0pW8kT4Cvkjr4jvAi9g';
const API_SECRET = 'wt6JYjasJsyM7k0SGM5eW23uUi32U0ARdjhb';
const HOST_ROLE = 1;
const ATTENDEE_ROLE = 0;
let isHostAccount = false;

// export function SetMeetingConfig() {
//   ZoomMtg.setZoomJSLib('https://source.zoom.us/2.1.1/lib', '/av');
//   ZoomMtg.preLoadWasm();
//   ZoomMtg.prepareJssdk();
// }

export function StartBreakout2Meeting() {

  if (localStorage.getItem('name')) {
    Name = localStorage.getItem('name');
  }
  let currentRole = 1;

  // let isHostAccount = false;
  ZoomMtg.setZoomJSLib('https://source.zoom.us/2.2.0/lib', '/av');
  ZoomMtg.preLoadWasm();
  ZoomMtg.prepareJssdk();
  console.log('Meeting Object', ZoomMtg);
  ZoomMtg.generateSignature({
    meetingNumber: Meeting_Num,
    apiKey: API_KEY,
    apiSecret: API_SECRET,
    role: currentRole,
    success: function (res) {
      console.warn('Signature generated', res.result);
      const currentSignature = res.result;
      // setTimeout(() => {

      ZoomMtg.init({
        leaveUrl: 'https://b2bevents.spicexchangeindia.com/',
        isSupportAV: true,
        success: (success) => {
          console.log("Init Success", success)
          console.log('ZoomMtg Obj', ZoomMtg)
          ZoomMtg.join({
            signature: currentSignature,
            meetingNumber: Meeting_Num,
            userName: Name,
            apiKey: API_KEY,
            userEmail: 'mohit@shilpmis.com',
            passWord: Meeting_Pass,
            success: (success) => {
              console.log('Meeting join success', success);
              // document.getElementById('zmmtg-root').style.opacity = '1';
              // document.getElementById('zmmtg-root').style.width = '50%';
              // document.getElementById('zmmtg-root').style.height = '50%';
              // document.querySelector('.meeting-client-inner').style.width = '50%';
              // document.querySelector('.meeting-client-inner').style.height = '50%';
              // document.querySelector('.meeting-client').style.width = '50%';
              // document.querySelector('.meeting-client').style.height = '50%';
              // document.getElementById('display-box').setAttribute('material', 'shader: html; target: .main-layout; fps: 10');
              // document.getElementById('display-box').setAttribute('material', 'shader: html; target: #wc-container-left; fps: 10');
              // document.getElementById('wc-footer').style.display = 'none'
              // document.getElementById('display-box').setAttribute('material', 'shader: html; target: #wc-container-left; fps: 10');
              // document.getElementById('wc-footer').style.display = 'none'
              // document.getElementById('b2b-hall').style.width = '50%';
              // document.getElementById('b2b-hall').style.height = '50%';
              // document.getElementById('display-box').setAttribute('visible', true);
              // document.getElementById('display-box').style.width = '50%';
              // document.getElementById('display-box').style.height = '50%';
              // setTimeout(() => {
              // let myInterval = setInterval(() => {
              //   if (document.querySelector('.join-audio-by-voip__join-btn')) {
              //     clearInterval(myInterval);
              //     // document.querySelector('.join-audio-by-voip__join-btn').click();
              //     // document.querySelector('.join-dialog__close').click();
              //     document.querySelector('.join-audio-by-voip__join-btn').click();
              //     document.getElementById('wc-footer').style.display = 'none'
              //   }
              // }, 500);
              // }, 2000);
              // setTimeout(() => {
              //     document.querySelector('.footer__leave-btn').click();
              // }, 15000);
            },
            error: (error) => {
              console.log('Meeting join error', error)
            }
          })
        },
        error: (error) => {
          console.log(error)
        }
      })
      // }, 1000);
    },
    error: (error) => {
      console.log('Signature generate error', error)
    }
  });
}

export function CloseMeeting() {
  console.log('Should close meeting now');

}
