import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import zoomInBtn from '../images/zoom in.png';
import zoomOutBtn from '../images/zoom out.png';
// import eventPosterImg from '../images/25th live on banner.jpg';
import backArrow from '../images/button arrow-1.png';
import dbFirebaseApp from '../functions/features/db-firebase-app';
import authFirebaseApp from '../functions/features/auth-firebase-app';
import '../functions/helpers/c-zoom-in-out'
import "aframe";
import "aframe-html-shader";
import ChatPanel from '../chatpanel/ChatPanel'
import startCameraImg from '../images/show-camera.png';
import stopCameraImg from '../images/hide-camera.png';
import startMicImg from '../images/unmute.png';
import stopMicImg from '../images/mute.png';
import screenshareImg from '../images/screenshare.png';
import stopScreenshareImg from '../images/stop-screenshare.png';
// import { StartWebinarMeeting } from '../functions/helpers/webinar-zoom';
import { isAlreadyExistInArray, removeEntry, SetAllImages } from '../functions/helpers/extra-functions';
import { StartBreakout1Meeting } from '../functions/helpers/breakout-zoom-1';
import { StartBreakout2Meeting } from '../functions/helpers/breakout-zoom-2';
import Header from '../header/header';
import LoadingPage from '../loading/loading-page';

let isAudioMuted = true;
let isVideoMuted = true;
let isScreenshareStarted = false;

const sceneName = 'breakout2';
const BreakoutSky = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/angels/kv-breakout1.jpg'
// const BreakoutSky = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/spice-board/launchbg.png'

let currentDoc = '';
export default class BreakoutPage2 extends Component {

    state = {
        commingUid: '',
        isloading:true,
    }



    componentDidMount() {

        let self = this;
        window.AFRAME.registerComponent("sky-breakoutpage2-component", {
            init: function () {
                let el = this.el;
                el.addEventListener("loaded", (e) => {
                    console.log('sky showed')
                    self.setState({
                        isloading: false
                    })
                });
            },
        });
        // authFirebaseApp.auth().onAuthStateChanged((user) => {
        //     if (user) {
        //         console.warn('user is ', user)
                // window.location.href = "/lobby-page"
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
        //         var uid = user.uid;
        //         this.setState({ commingUid: uid });
        //         console.warn('state uid is ::', this.state.commingUid)
        //         // ...
        //     }
        //     else {
        //         console.warn('user not found')
        //         window.location.href = '/'
        //         // User is signed out
        //         // ...
        //     }
        // });
        localStorage.setItem('Location', 'breakout-page')
        localStorage.setItem('sceneName', sceneName)

        // this.addNewEntry();
        // this.updateCollection();
        // this.getUserDetails(sceneName);
        StartBreakout2Meeting();

    }

    updateCollection = () => {
        const db = dbFirebaseApp.firestore();
        db.collection("AngelsKnowledgeVillage").doc(localStorage.getItem('docRef')).update({
            sceneName: sceneName,
        })
            .then(function (docRef) {
                console.log("Document written with ID: ", docRef);
                currentDoc = docRef;
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });
    }

    addNewEntry = () => {
        const db = dbFirebaseApp.firestore();
        db.collection("AngelsKnowledgeVillage").add({
            name: localStorage.getItem('UserName'),
            profilePicUrl: localStorage.getItem('ProfilePicUrl'),
            sceneName: sceneName,
            uid: localStorage.getItem('uid')
        })
            .then(function (docRef) {
                console.log("Document written with ID: ", docRef);
                currentDoc = docRef;
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });
    }

    goBackToLobby = () => {
        window.location.href = '/entrance'
    }


    toggleCameraView = () => {
        // console.warn('Change Camera View');
        if (document.querySelector('#myCameraBreakout2Room').getAttribute('fov') === '50') {
            // console.warn('Near');
            document.querySelector('#myCameraBreakout2Room').setAttribute('fov', '32.5');
            document.getElementById("Zoom-In").setAttribute('visible', 'false')
            document.getElementById("Zoom-Out").setAttribute('visible', 'true')
        } else {
            // console.warn('Far');
            document.querySelector('#myCameraBreakout2Room').setAttribute('fov', '50');
            document.getElementById("Zoom-Out").setAttribute('visible', 'false')
            document.getElementById("Zoom-In").setAttribute('visible', 'true')
        }
    }

    getUserDetails(sceneName) {
        const db = dbFirebaseApp.firestore();
        const userCollection = db.collection('AngelsKnowledgeVillage');
        // let ExistingUserQuery = new Promise((resolve, reject) => {
        let profilePicArray = [];
        userCollection.where('sceneName', '==', sceneName)
            .onSnapshot(function (querySnapshot) {
                querySnapshot.docChanges().forEach(function (change) {
                    if (change.type === "added" && change.type !== "modified") {
                        console.log("New city: ", change.doc.data());
                        let myData = change.doc.data();
                        myData.id = change.doc.id;
                        if (!isAlreadyExistInArray(profilePicArray, myData.uid)) {
                            profilePicArray.push(myData)
                        }

                        setTimeout(() => {
                            // // for (let i = 0; i < profilePicArray.length; i++) {
                            //     // console.warn('comminguid is :: ', this.state.commingUid)
                            //     console.warn('profilePicArray length', profilePicArray.length)
                            //     // if (this.state.commingUid === profilePicArray[i].uid) {
                            //     for (let j = 0; j < profilePicArray.length; j++) {
                            let imageElement = document.createElement('img')
                            let aimageElement = document.createElement('a-image')
                            let atextElement = document.createElement('a-text')
                            let aentityElement = document.createElement('a-entity')
                            imageElement.setAttribute('id', '1img-' + myData.uid)
                            aimageElement.setAttribute('id', '1aimg-' + myData.uid)
                            atextElement.setAttribute('id', '1atext-' + myData.uid)
                            aentityElement.setAttribute('id', '1aentity-' + myData.uid)
                            atextElement.setAttribute('value', myData.name)
                            atextElement.setAttribute('color', "black")
                            imageElement.setAttribute('crossOrigin', 'anonymous')
                            imageElement.setAttribute('src', myData.profilePicUrl)
                            document.getElementById('breakoutAssets').appendChild(imageElement)
                            aentityElement.appendChild(aimageElement)
                            aentityElement.appendChild(atextElement)
                            atextElement.setAttribute('position', '-0.5 -0.5 0');
                            aimageElement.setAttribute('scale', '0.85 0.85 0.85');
                            atextElement.setAttribute('scale', '0.75 0.75 0.75');

                            document.getElementById('asceneBreakout').appendChild(aentityElement)
                            document.getElementById('1aimg-' + myData.uid).setAttribute('src', '#1img-' + myData.uid)
                            // document.getElementById('1aimg-' + myData.uid).setAttribute('position', `${j} 0 -3`)
                            // document.getElementById('1atext-' + profilePicArray[j].uid).setAttribute('position', `${j} 1 -3`)
                            // aentityElement.setAttribute('position', `1 1 -3`)
                            SetAllImages(profilePicArray, '1aentity-');
                            //         }
                            //         // }
                            //     // }
                        }, 1000);
                    }
                    if (change.type === "modified") {
                        console.log("Modified city: ", change.doc.data());
                    }
                    if (change.type === "removed" && change.type !== "modified") {
                        console.log("Removed city: ", change.doc.data());
                        let updatedArrray = removeEntry(profilePicArray, change.doc.data().uid)
                        console.warn('updated array is ;:', updatedArrray)
                        console.warn('remove 1', document.getElementById('1img-' + change.doc.data().uid))
                        if (document.getElementById('1img-' + change.doc.data().uid) &&
                            document.getElementById('1aimg-' + change.doc.data().uid) &&
                            document.getElementById("asceneBreakout")) {
                            document.getElementById('1img-' + change.doc.data().uid).remove();
                            var aImage = document.getElementById('1aentity-' + change.doc.data().uid);
                            document.getElementById("asceneBreakout").removeChild(aImage);
                        }
                    }
                    // console.log(doc.id, " => ", doc.data());

                });

                // resolve(profilePicArray);

            });
        // });
        // ExistingUserQuery.then(result => {
        // console.warn('result is ::', result)
        // if (result === 'Not Found') {
        //     alert('User details not found');
        //     // document.getElementById('login-btn').removeAttribute('disabled');
        // } else {
        //     console.warn('result', result)
        //     setTimeout(() => {
        //         for (let i = 0; i < result.length; i++) {
        //             console.warn('comminguid is :: ', this.state.commingUid)
        //             console.warn('result uid', result[i].uid)
        //             // if (this.state.commingUid === result[i].uid) {
        //                 this.CreateImgAndAimage(result);
        //             // }
        //         }
        //     }, 1000);


        // }
        // }).catch(error => {
        //     console.error('Already existing user check error', error);
        // });
    }
    toggleMicMute = () => {
        if (document.querySelector('.join-audio-container_btn')) {
            document.querySelector('.join-audio-container_btn').click();
        }
        isAudioMuted = !isAudioMuted;
        document.getElementById('audioBtn').setAttribute('src', isAudioMuted ? '#micMuteImg' : '#micUnmuteImg');
    }

    toggleCameraMute = () => {
        if (document.querySelector('.send-video-container__btn')) {
            document.querySelector('.send-video-container__btn').click();
        }
        isVideoMuted = !isVideoMuted;
        document.getElementById('videoBtn').setAttribute('src', isVideoMuted ? '#cameraMuteImg' : '#cameraUnmuteImg');
    }

    toggleScreenshare = () => {
        if (isScreenshareStarted) {
            this.stopScreenshare();
        } else {
            this.startScreenshare();
        }
    }

    startScreenshare = () => {
        if (document.getElementsByClassName('footer-button__button')[1]) {
            document.getElementsByClassName('footer-button__button')[1].click();
            isScreenshareStarted = true;
        }

    }

    stopScreenshare = () => {
        if (document.querySelector('.sharer_button--pattern1')) {
            document.querySelector('.sharer_button--pattern1').click();
            isScreenshareStarted = false;
        }
    }

    CreateImgAndAimage = (result) => {
        for (let i = 0; i < result.length; i++) {
            let imageElement = document.createElement('img')
            let aimageElement = document.createElement('a-image')
            imageElement.setAttribute('id', '1img-' + result[i].uid)
            aimageElement.setAttribute('id', '1aimg-' + result[i].uid)
            imageElement.setAttribute('crossOrigin', 'anonymous')
            imageElement.setAttribute('src', result[i].profilePicUrl)
            document.getElementById('breakoutAssets').appendChild(imageElement)
            document.getElementById('asceneBreakout').appendChild(aimageElement)
            document.getElementById('1aimg-' + result[i].uid).setAttribute('src', '#1img-' + result[i].uid)
            document.getElementById('1aimg-' + result[i].uid).setAttribute('position', `${i} 0 -3`)

        }
        // document.getElementById('1aimg-'+result[0].uid).setAttribute('position', '1 0 -3')
        // document.getElementById('1aimg-'+result[1].uid).setAttribute('position', '0 0 -3')
        // this.getUserDetails(sceneName);
    }

    render() {
        return (
            <div>
                {this.state.isloading && <LoadingPage />}
                <Header />
                <a-scene id="asceneBreakout" loading-screen="enabled: false" vr-mode-ui="enabled: false" style={{ zIndex: 2 }}>
                    <a-assets id="breakoutAssets">
                        <img id="sky" crossOrigin="anonymous" src={BreakoutSky} alt="background" />
                        <img id="backArrowBreakoutNurse" src={backArrow} alt='back' />
                        <img id="zoomInBtn" src={zoomInBtn} alt="Zoom In Button" />
                        <img id="zoomOutBtn" src={zoomOutBtn} alt="Zoom Out Button" />
                        <img id="micMuteImg" src={stopMicImg} alt="Stop Mic" />
                        <img id="micUnmuteImg" src={startMicImg} alt="Start Mic" />
                        <img id="cameraMuteImg" src={stopCameraImg} alt="Stop Camera" />
                        <img id="cameraUnmuteImg" src={startCameraImg} alt="Start Camera" />
                        <img id="screenshareImg" src={screenshareImg} alt="ss" />
                        <img id="stopScreenshareImg" src={stopScreenshareImg} alt="sss" />
                    </a-assets>
                    <a-camera look-controls="disableVerticalMotion:true; magicWindowTrackingEnabled: false; gyroModeEnabled: false; reverseTouchDrag: true;" c-zoom-in-out id="myCameraBreakout2Room" fov="65" wasd-controls="enabled:false">
                        <a-entity
                            cursor="rayOrigin: mouse; fuse: false; disableDragClick: true"
                            raycaster="objects: .raycaster-solid; recursive: false;"
                        />
                    </a-camera>

                    <a-sky src="#sky" rotation="0 0 0" sky-breakoutpage2-component></a-sky>
                    <a-image src="#backArrowBreakoutNurse" id="entertainment-back" position="-15.5 2.4 9" scale="1.5 1.5 1.5" width="2" height='1' look-at="#myCameraBreakout1Room" class="raycaster-solid clickable" onClick={this.goBackToLobby}></a-image>
                    <a-image src="#micUnmuteImg" id="audioBtn" position="-1.2 -0.2 -8" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleMicMute} />
                    <a-image src="#cameraUnmuteImg" id="videoBtn" position="0 -0.2 -8" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleCameraMute} />
                    <a-image src="#screenshareImg" id="screenshareBtn" position="1.2 -0.2 -8" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleScreenshare} />
                    <a-image color="white" id="display-box" visible={true} position="0 3.8 -20" rotation="0 0 0" scale="23 10 1" class="raycaster-solid clickable" onClick={this.toggleCameraView} />

                      </a-scene>
                {/* <ChatPanel roomName="knowledgeVillageRoom2" /> */}
                {/* <Footer /> */}

            </div>
        )
    }
}
