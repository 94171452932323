/* eslint-disable  */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import B2bWebinar from "../breakout/b2b-hall";

// import firebase from './firebase';

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

// import Vimeo  from "@vimeo/player";
import {
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import dbFirebaseApp from "../functions/features/db-firebase-app";

// const Vimeo = require('@vimeo/player')
import * as Player from "@vimeo/player/dist/player.js";
import AlertDialog from "./alertDialog";

// require('./player.js');

let Logo1 =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/private/image2d/spice-board/sb-general/logo2.png";
let Logo2 =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/private/image2d/spice-board/sb-general/spice-xhange-logo.png";

const auditoriumEntry =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/spice-board/room-c.mp4";
const webinarEntry =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/spice-board/spiceLaunch.mp4";

const auditoriumImg =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/spice-board/launchbg.png";
const raiseHandImg =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/private/image2d/spice-board/spice-event/spice-button.png";
const askToUnmute =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/private/image2d/spice-board/spice-event/unmute.png";
const rotationImg =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/private/image2d/spice-board/rotate-smartphone.png";

const expoName = "spice";
var iOS = /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.userAgent)
export class landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoEnded: false,
      webinarVideo: false,
      isOtherCat: false,
      name: "",
      companyName: "",
      country: "",
      mobile: "",
      email: "",
      category: "",
      products: "",
      isUserRegister: true,
      isValidUser: true,
      posterVideoLink: "",
      iframeLink: "",
      streamyardLink: "",
      liveSreamStart: false,
      isEventStart:false,
      // showAccessDenied: false,
      isEventName: "",
      alertDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    };
  }


  componentDidMount() {
    document.getElementById("zmmtg-root").style.display = "none";
    document.getElementById("")
    this.particlesInit();
    this.getUserDetails();
    console.log("iframeLink", this.state.iframeLink);
    console.log("posterVideoLink", this.state.posterVideoLink);
    
  }


  getUserDetails() {
    let self = this;
    const db = dbFirebaseApp.firestore();
    const userCollection = db.collection("SpiceEventFlag");
    // let ExistingUserQuery = new Promise((resolve, reject) => {
    let profilePicArray = [];
    userCollection
      .where("expo", "==", expoName)
      .onSnapshot(function (querySnapshot) {
        querySnapshot.docChanges().forEach(function (change) {
          console.log("video url", change.doc.data());
          self.setState({
            posterVideoLink: change.doc.data().posterVideoLink,
            iframeLink: change.doc.data().iframeLink,
            streamyardLink: change.doc.data().streamyardLink,
            isEventName : change.doc.data().eventName,
            isEventStart:change.doc.data().isEventStart
          });
          if (change.doc.data().poster) {
            if (document.getElementById("webinarEntryVideo")) {
              document.getElementById("webinarEntryVideo").volume = 0
                document.getElementById("webinarEntryVideo").style.display =
                  "block";
            }
          } else if (!change.doc.data().poster) {
            if (document.getElementById("webinarEntryVideo")) {
              document.getElementById("webinarEntryVideo").style.display =
                "none";
            }
          }
          if (change.doc.data().gotoLiveBtn) {
            document.getElementById("raiseHandImg").style.display = "block";
          } else if (!change.doc.data().gotoLiveBtn) {
            document.getElementById("raiseHandImg").style.display = "none";
          }

          if (change.type === "modified") {
            console.log("Modified city: ", change.doc.data());
            console.log("New city: ", change.doc.data());
            if (change.doc.data().poster) {
              document.getElementById("webinarEntryVideo").volume = 0
              if (document.getElementById("webinarEntryVideo")) {
                  document.getElementById("webinarEntryVideo").style.display ="block";
              }
            } else if (!change.doc.data().poster) {
              if (document.getElementById("webinarEntryVideo")) {
                document.getElementById("webinarEntryVideo").style.display ="none";
              }
            }
            if (change.doc.data().gotoLiveBtn) {
              document.getElementById("raiseHandImg").style.display = "block";
            } else if (!change.doc.data().gotoLiveBtn) {
              document.getElementById("raiseHandImg").style.display = "none";
            }
          }
        });
      });
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  particlesInit() {
    var generator = document.getElementById("particleGenerator");
    var particleCount = 200;
    for (var i = 0; i < particleCount; i++) {
      var size = this.getRandomInt(2, 6);
      var n =
        '<div class="particle" style="top:' +
        this.getRandomInt(15, 95) +
        "%; left:" +
        this.getRandomInt(5, 95) +
        "%; width:" +
        size +
        "px; height:" +
        size +
        "px; animation-delay:" +
        this.getRandomInt(0, 30) / 10 +
        "s; background-color:rgba(" +
        this.getRandomInt(46, 46) +
        "," +
        this.getRandomInt(49, 49) +
        "," +
        this.getRandomInt(146, 146) +
        "," +
        this.getRandomInt(2, 8) / 10 +
        ');"></div>';
      var node = document.createElement("div");
      node.innerHTML = n;
      generator.appendChild(node);
    }
  }
  onRegisterLogin(e) {
    e.preventDefault();
    // window.location.href="/rlanding"
    this.setState({
      isUserRegister: false,
    });
  }
  backToLogin(e) {
    e.preventDefault();
    // window.location.href="/rlanding"
    this.setState({
      isUserRegister: true,
    });
  }
  onLoginUser(e) {
    e.preventDefault();
    const UserEmail = document.getElementById("vemail").value;

    if (UserEmail === "") {
      alert("Please Enter Your Email");
    } else {
      const dbRef = dbFirebaseApp.firestore();
      const collectionRef = dbRef.collection("B2bSpiceEvents");

      collectionRef
        .where("email", "==", UserEmail)
        .get()
        .then((querySnapshot) => {
          let userCount = 0;
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            userCount = 1;
          });
          console.log("userCount", userCount);
          if (userCount > 0) {
            this.setState(
              {
                isVideoEnded: true,
              },
              () => {
                document.getElementById("entryVideo").play();
                // document.getElementById("entryVideo").muted=false;
                document
                  .getElementById("entryVideo")
                  .addEventListener("ended", this.completeVideo, false);
              }
            );
          } else {
            alert("Please Register First");
          }
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
    document.getElementById('webinarEntryVideo').style.display="none"
  }


  onSubmitLogin(e) {
    e.preventDefault();
    const name = document.getElementById("name").value;
    const companyName = document.getElementById("companyName").value;
    const country = document.getElementById("country").value;
    const mobile = document.getElementById("mobile").value;
    const email = document.getElementById("email").value;
    const website = document.getElementById("website").value;
    const category = document.getElementById("category").value;
    const poducts = document.getElementById("poducts").value;
    // const membership = document.getElementById("membership").value;

    var currentdate = new Date();
    var datetime =
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      "  " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();
    // console.log('Date',datetime);

    if (name === "") {
      alert("Name can not be blank");
    } else if (companyName === "") {
      alert("Company Name can not be blank");
    } else if (country === "") {
      alert("Country can not be blank");
    } 
    // else if (mobile === "") {
    //   alert("Mobile Number can not be blank");
    // }
     else if (email === "") {
      alert("Email can not be blank");
    } else if (category === "0") {
      alert("Participant Category can not be blank");
    } else if (poducts === "") {
      alert("Products can not be blank");
    } else {
      const dbRef = dbFirebaseApp.firestore();
      const collectionRef = dbRef.collection("B2bSpiceEvents");
      collectionRef
        .add({
          date: datetime,
          name: name,
          companyName: companyName,
          country: country,
          mobile: mobile,
          email: email,
          website: website,
          category: category,
          poducts: poducts,
          eventName : this.state.isEventName,
          // membership : membership
        })
        .then((docRef) => {
          console.log("Document Data", docRef);

          this.setState(
            {
              isVideoEnded: true,
            },
            () => {
              document.getElementById("entryVideo").play();
              document
                .getElementById("entryVideo")
                .addEventListener("ended", this.completeVideo, false);
            }
          );
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }
  completeVideo = (e) => {
    // this.setState({
    //   liveSreamStart : true
    // })
    const self = this;
    e.preventDefault();
    document.getElementById("entryVideo").pause();
    document.getElementById("webinarEntryVideo").style.display="block"
    // this.setState({
    //   liveSreamStart : true
    // })
    // window.location.href="https://zoom.us/j/96512174374?pwd=NFRLNmVJTkdnc3cyeUZ3SlZIc3c4QT09"
    if(this.state.isEventStart){

      window.location.href=`${this.state.streamyardLink}`
    }
    else{
    document.getElementById("videoContentEntry").style.display = "none";
    document.getElementById("conferenceBgImg").style.display = "block";
     setTimeout(() => {
    if (document.getElementById("webinarEntryVideo")) {
      document.getElementById("webinarEntryVideo").play();
      document.getElementById("webinarEntryVideo").volume = 1
      self.setState(
        {
          webinarVideo: true,
        },
        () => {
          // document.getElementById("zmmtg-root").style.display = "block";
        }
      );
    }
    }, 12000);
    }
  };

  gotoZoom = () => {
    window.location.href=`${this.state.streamyardLink}`
  }

  enterKeyHandler = (e) => {
    if (e.keyCode === 13) {
      this.onSubmitLogin(e);
    }
  };
  goToStreamyard = (value) => {
    this.setVimeoMute(value);

    
    this.setState({
      alertDialog: {
        isOpen: true,
        title: "Click Here To Start Audio",
        subTitle: ""
    },
    }, ()=>{
      window.open(`${this.state.streamyardLink}`, "_blank");
    })
    // document.getElementById("askToUnmute").style.display = "block"

  };


   setVimeoMute(isMuted){
    const iframe = document.querySelector('iframe');
      const player = new Player(iframe);
  
      player.setMuted(isMuted).then(function(muted) {
        // muted was turned on
    }).catch(function(error) {
        // an error occurred
    });
    
  }
  setAlertDialog = () => {
    this.setState({
        alertDialog: {
            isOpen: false,
            title: "",
            subTitle: ""
        },
    },()=>{
      this.setVimeoMute(false);
    });
  };

  // toggleAccessDenied = () => {
  //   this.setState({
  //     showAccessDenied: false
  //   },()=>{
      
  //   });
  // };

  render() {
    return (
      <div>
        {!this.state.isVideoEnded && (
          <div>
            
            <div class="">
              <div className="flexlogoright">
                <img
                  src={Logo1}
                  alt="Spice Board"
                  className="spice-logo-width-loading"
                />
              </div>
              <div className="flexlogo">
                <img
                  src={Logo2}
                  alt="Spice Xchange Board"
                  className="widthlogo"
                />
              </div>
            </div>
            <div className="mainclasslayer"></div>
            <div id="bg1"></div>
            <div id="bg2"></div>

            <div id="particleGenerator"></div>
            <div className="containerunite">
              <div className="formunite">
                <Paper elevation={2} className="exhibitorTopBarunite">
                  {/* {
                    (this.state.uniteform) && */}
                  {this.state.isUserRegister && (
                    <div className="flexbutton">
                      <Box>
                      <DialogTitle className="eventTitle">
                        {this.state.isEventName ? this.state.isEventName : "Spice Event" }
                    </DialogTitle>
                        <Grid container spacing={1}>
                          <Grid container item xs={12} md={6}>
                            <label className="label">
                              <input
                                id="name"
                                type="Name"
                                name="Name"
                                // value={email}
                                // onChange={this.handleChange}
                                className="inputformclass"
                                placeholder="Name*"
                                onKeyUp={this.enterKeyHandler}
                              />
                            </label>
                          </Grid>
                          <Grid container item xs={12} md={6}>
                            <label className="label">
                              <input
                                id="companyName"
                                type="CompanyName"
                                name="CompanyName"
                                // value={email}
                                // onChange={this.handleChange}
                                className="inputformclass"
                                placeholder="Company Name*"
                                onKeyUp={this.enterKeyHandler}
                              />
                            </label>
                          </Grid>
                          <Grid container item xs={12} md={6} v>
                            <label className="label">
                              <input
                                id="country"
                                type="Country"
                                name="Country"
                                // value={email}
                                // onChange={this.handleChange}
                                className="inputformclass"
                                placeholder="Country*"
                                onKeyUp={this.enterKeyHandler}
                              />
                            </label>
                          </Grid>
                          <Grid container item xs={12} md={6} v>
                            <label className="label">
                              <input
                                id="website"
                                type="Website"
                                name="Website"
                                // value={email}
                                // onChange={this.handleChange}
                                className="inputformclass"
                                placeholder="Website"
                                onKeyUp={this.enterKeyHandler}
                              />
                            </label>
                          </Grid>
                          <Grid container item xs={12} md={6}>
                            <label className="label">
                              <input
                                id="mobile"
                                type="Number"
                                name="Mobile"
                                // value={email}
                                // onChange={this.handleChange}
                                className="inputformclass"
                                placeholder="Mobile"
                                onKeyUp={this.enterKeyHandler}
                              />
                            </label>
                          </Grid>
                          <Grid container item xs={12} md={6}>
                            <label className="label">
                              <input
                                id="email"
                                type="Email"
                                name="Email"
                                // value={email}
                                // onChange={this.handleChange}
                                className="inputformclass"
                                placeholder="Email*"
                                onKeyUp={this.enterKeyHandler}
                              />
                            </label>
                          </Grid>
                          <Grid container item xs={12} md={6}>
                            <select className="inputformclass" id="category">
                              <option className="inputformclass" value="0">
                                Select Participant Category*
                              </option>
                              <option
                                className="inputformclass"
                                value="Registered Exporters with Spices Board"
                              >
                                Registered Exporters with Spices Board
                              </option>
                              <option
                                className="inputformclass"
                                value="Importer"
                              >
                                Buyer/Importer
                              </option>
                              <option
                                className="inputformclass"
                                value="Others"
                              >
                                Others
                              </option>
                            </select>
                          </Grid>

                          {this.state.isOtherCat && (
                            <Grid container item xs={12} md={6}>
                              <label className="label">
                                <input
                                  id="otherCat"
                                  type="OtherCat"
                                  name="OtherCat"
                                  // value={Website}
                                  // onChange={this.handleChange}
                                  className="inputformclass"
                                  placeholder="Please Specify"
                                  onKeyUp={this.enterKeyHandler}
                                />
                              </label>
                            </Grid>
                          )}
                          <Grid container item xs={12} md={6}>
                            <label className="label">
                              <input
                                id="poducts"
                                type="Products"
                                name="Products"
                                // value={Website}
                                // onChange={this.handleChange}
                                className="inputformclass"
                                placeholder="Deals in (Major products)*"
                                // onKeyUp={this.enterKeyHandler}
                              />
                            </label>
                          </Grid>
                          {/* <Grid container item xs={12} md={6}>
                            <select className="inputformclass" id="membership">
                              <option className="inputformclass" value="0">
                              Existing Member Of Spice Board*
                              </option>
                              <option
                                className="inputformclass"
                                value="Yes"
                              >
                               Yes
                              </option>
                              <option
                                className="inputformclass"
                                value="No"
                              >
                                No
                              </option>
                            </select>
                          </Grid> */}
                        </Grid>
                        {/* <TextField label="Password" id="password" onChange={this.handleChange} variant="outlined" /><br /> */}

                        <button
                          className="submitunitebtn"
                          id="login-button"
                          onClick={(e) => this.onSubmitLogin(e)}
                        >
                          Submit
                        </button>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={(e) => this.onRegisterLogin(e)}
                        >
                          {" "}
                          If You Are Already Register Please{" "}
                          <a
                            style={{ cursor: "pointer", color: "#1c0751" }}
                            onClick={(e) => this.onRegisterLogin(e)}
                          >
                            {" "}
                            Click Here To Login
                          </a>{" "}
                        </a>
                        {/* <button
                          className="registerBtn"
                          id="login-button"
                          onClick={(e) => this.onRegisterLogin(e)}
                        >
                          Already Register Please click here
                        </button> */}
                      </Box>
                    </div>
                  )}
                  {!this.state.isUserRegister && (
                    <div className="flexbutton">
                      <Box>
                        <Grid container spacing={1}>
                          <Grid container item xs={12} md={12}>
                            <label className="label">
                              <input
                                id="vemail"
                                type="Email"
                                name="vEmail"
                                // value={email}
                                // onChange={this.handleChange}
                                className="inputformclass"
                                placeholder="Email*"
                                // onKeyUp={this.enterKeyHandler}
                              />
                            </label>
                          </Grid>
                        </Grid>
                        <button
                          className="submitunitebtn"
                          id="login-button"
                          onClick={(e) => this.onLoginUser(e)}
                        >
                          Login
                        </button>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={(e) => this.backToLogin(e)}
                        >
                          Go Back to{" "}
                          <a style={{ cursor: "pointer", color: "#1c0751" }}>
                            Register{" "}
                          </a>
                        </span>
                      </Box>
                    </div>
                  )}

                  {/* } */}
                </Paper>
                {/* <h3
                  className="color-text marginbottomzero"
                  onClick={(e) => this.onSubmitLogin(e)}
                >
                  Enter Event
                </h3> */}
              </div>
            </div>
          </div>
        )}
        {this.state.isVideoEnded && (
          <div>
          <div className="overlayPotraitMode">
              <div id="portraitModeOnly">
                <img alt="landscape only" src={rotationImg} />
                <p>This website is only viewable in landscape mode</p>
              </div>
            </div>
          <div id="conferenceBg">
            <div id="videoContentEntry">
              <video
                id="entryVideo"
                src={auditoriumEntry}
                type="video/mp4"
                autoPlay={iOS}
                muted={iOS}
                className="mainvideoEleHome"
                playsInline
                controlsList="nodownload"
                onContextMenu={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
             {this.state.isEventStart && <button className="sb-firstpage-skip" id="skipButtonPromo" onClick={this.gotoZoom}>Skip</button>}
            </div>
            <img
              src={auditoriumImg}
              // className="sb-auditorium-bg"
              className="centerimglaunch"
              id="conferenceBgImg"
              alt=""
              style={{ display: "none" }}
            />
            <div
              id="conferenceDiv"
              // className="positionmeet"
              className="iframe-vimeo"
            ></div>
          </div>
          </div>
        )}
        {/* {this.state.isVideoEnded && this.state.webinarVideo && ( */}
        <div id="conferenceWebinar">
          {/* <B2bWebinar /> */}
          <div id="videoWebinar">
            <video
              id="webinarEntryVideo"
              src={this.state.posterVideoLink}
              type="video/mp4"
              className="mainvideoWebinar"
              playsInline
              autoPlay
              muted={iOS || this.state.isEventStart }
              loop
              controlsList="nodownload"
            />
          </div>
        </div>
        {/* )} */}
        {/* <div style={{ padding: "56.25% 0 0 0", position: "relative" , overflow:"hidden"}}> */}
        {this.state.liveSreamStart && (
          <div>
            <iframe
              src={this.state.iframeLink}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              id="iframeVideo"
              style={{
                position: "absolute",
                top: "18.15vh",
                height: "39.8vh",
                left: "33vw",
                width: "32.1vw",
                background: "black",
                objectFit: "fill",
                overflow: "hidden",
              }}
              title="Goa Test Event"
            ></iframe>
            {/* </div> */}
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        )}
        <img
          src={raiseHandImg}
          // className="sb-auditorium-bg"
          className="raiseHandImage"
          id="raiseHandImg"
          alt=""
          onClick={() => this.goToStreamyard(true)}
          // style={{ display: "none" }}
        />
        <img
          src={askToUnmute}
          // className="sb-auditorium-bg"
          className="raiseHandImage"
          id="askToUnmute"
          alt=""
          onClick={() => this.setVimeoMute(false)}
          style={{ display: "none" }}
        />
        <AlertDialog
            alertDialog={this.state.alertDialog}
            setAlertDialog={this.setAlertDialog}
          />
      </div>
    );
  }
}

export default landing;
